var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-avatar',{staticClass:"rounded-lg mx-auto d-block",attrs:{"tile":"","width":_vm.width ? _vm.width : '92.5',"height":_vm.height ? _vm.height : '92.5',"color":"rgba(242, 242, 242, 0.075)"}},[(_vm.imageUrl)?_c('v-img',{attrs:{"src":_vm.imageUrl},on:{"loadstart":function($event){_vm.imageIsLoad = true},"load":function($event){_vm.imageIsLoad = false},"error":function($event){_vm.imageUrl = _vm.imageDefault}}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageIsLoad || _vm.loading),expression:"imageIsLoad || loading"}],attrs:{"indeterminate":_vm.imageIsLoad || _vm.loading,"color":"#64B5F6"}})],1),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"\n                      d-flex\n                      transition-fast-in-fast-out\n                      lighten-3\n                      v-card--reveal\n                      white--text\n                    ",staticStyle:{"border-radius":"5px","background-color":"rgb(74 183 98 / 60%)"},style:('width:' + (_vm.width ? _vm.width + 'px;' : '92.5px;') + 'height:' + (_vm.height ? _vm.height + 'px;' : '92.5px;'))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"#000","disabled":_vm.loading},on:{"click":function($event){return _vm.$refs.uploader.click()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Ganti gambar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"#e74c3c","disabled":_vm.loading},on:{"click":function($event){_vm.imageUrl = _vm.imageDefault}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus gambar")])])],1):_vm._e()]),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg"},on:{"change":_vm.onFileChanged}})],1):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }