<template>
  <div class="pt-2">
    <v-row>
      <v-col cols="12">
        <v-hover v-slot="{ hover }">
          <v-card flat class="transparent">
            <v-card-text class="pa-0">
              <v-avatar
                tile
                class="rounded-lg mx-auto d-block"
                :width="width ? width : '100%'"
                :height="height ? height : '185'"
                color="rgba(242, 242, 242, 0.075)"
              >
                <v-img
                  v-if="imageUrl"
                  :src="imageUrl"
                  @loadstart="imageIsLoad = true"
                  @load="imageIsLoad = false"
                  @error="imageUrl = imageDefault"
                >
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      v-show="imageIsLoad || loading"
                      :indeterminate="imageIsLoad || loading"
                      color="#64B5F6"
                    ></v-progress-circular>
                  </v-row>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        lighten-3
                        v-card--reveal
                        white--text
                      "
                      style="
                        border-radius: 5px;
                        background-color: rgb(74 183 98 / 60%);
                      "
                      :style="'height: 185px;'"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            icon
                            color="#000"
                            @click="$refs.uploader.click()"
                            :disabled="loading"
                          >
                            <v-icon small>mdi-image-search</v-icon>
                          </v-btn>
                        </template>
                        <span>Ganti gambar</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            icon
                            color="#e74c3c"
                            :disabled="loading"
                            @click="imageUrl = imageDefault"
                          >
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Hapus gambar</span>
                      </v-tooltip>
                    </div>
                  </v-expand-transition>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="onFileChanged"
                  />
                </v-img>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  props: ["value", "width", "height"],
  data: () => ({
    loading: false,
    imageIsLoad: false,
    imageDefault: "https://via.placeholder.com/150/ECF0F1/4AB762?text=No+Image",
    imageUrl: "",
  }),

  mounted() {
    this.imageUrl = this.value ? this.value : this.imageDefault;
  },

  watch: {
    imageUrl: function (val) {
      this.$emit("input", val);
    },
    value: function (val) {
      this.imageUrl = val ? val : this.imageDefault;
      this.$emit("input", this.imageUrl);
    },
  },

  methods: {
    async onFileChanged(e) {
      if (e.target.files.length > 0) {
        let file = e.target.files[0];
        e.target.value = '';

        let formData = new FormData();
        formData.append("image", file);

        this.loading = true;

        fetch("https://media.kolonia.id/image.php", {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            this.loading = false;
            this.imageUrl = res.data.fileuri;
          })
          .catch((err) => {
            this.loading = false;
            this.imageUrl = this.value ? this.value : this.imageDefault;
          });
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>